.contactMe-Container{
    width: 100%;
    height: auto;
    min-height: 80vh;
}
.contactMe-Wrapper{
    width: calc(100% - 512px);
    height: 100%;
    padding: 128px 256px;
    
}

.contactMe{
    width: 100%;
    height: 100%;
    background-color: #F9F9F9;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
}

.SendMessage{
    width: calc(50% - 128px);
    padding: 32px 64px;
    display: flex;
    flex-direction: column;
    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
}

.SendMessage h5{
    margin-block: 32px;
}

.SendMessage h6{
    margin-block: 8px;
}

.SendMessage input{
    width: 100%;
    height: 30px;
    border: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    outline: none;
}
.SendMessage textarea{
    width: 100%;
    height: 128px;
    min-height: 128px;
    border: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    resize: vertical;
}
.SendMessage button{
    width: 150px;
    height: 35px;
    align-self: flex-end;
    margin-top: 2rem;
    border: 0;
    font-family: 'Poppins',sans-serif;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    font-weight: bold;
    background: linear-gradient(90deg, rgba(250,63,38,1) 0%, rgba(253,118,25,1) 100%);
    color: white;
    border-radius: 20px;
    position: relative;
}

.SendMessage button .shine{
    height: 100%;
    width: 0;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgba(255, 255, 255, 0.4);
    -webkit-transition: none;
	   -moz-transition: none;
	        transition: none;
}

.SendMessage button:hover{
    cursor: pointer;
}
.SendMessage button:hover .shine{
    width: 120%;
    background-color: rgba(255,255,255,0);
    
    -webkit-transition: all 0.4s ease-in-out;
        -moz-transition: all 0.4s ease-in-out;
            transition: all 0.4s ease-in-out;
}

.SendMessage button svg{
    width: 16px;
    height: 16px;
}

.send_message_loading{
    width: 48px;
    height: 48px;
}


.ContactInfo{
    width: 50%;
    border-left: 0.5px solid rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 32px 0;
}
.ContactInfo h5{
    margin-block: 32px;
}
.ContactInfo h6{
    margin-block: 8px;
}
.ContactInfo p{
    width: auto;
    height: 35px;
    padding: 6px 32px;
    border: 0;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
    background-color: #fff;
    font-size: 24px;
    line-height: 35px;
    margin-block: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}


.icons{
    width: 32px;
    height: 32px;
}

/* Responsive */

@media only screen and (max-width: 1250px) {
    .contactMe-Wrapper{
        width: calc(100% - 256px);
        height: 100%;
        padding: 128px 128px;
        
    }
}
@media only screen and (max-width: 1024px) {
    h5{
        font-size: 18px;
    }
    h6{
        font-size: 16px;
    }
}
@media only screen and (max-width: 960px) {
    .ContactInfo p{
        font-size: 16px;
    }
}
@media only screen and (max-width: 900px) {
    .contactMe{
        flex-direction: column;
    }
    .SendMessage{
        width: calc(100% - 128px);
        border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
        border-right: 0;
        justify-content: center;
        align-items: center;
    }
    .ContactInfo{
        width: 100%;
        border-top: 0.5px solid rgba(0, 0, 0, 0.2);
        border-left: 0;
        justify-content: center;
        align-items: center;
    }
}
@media only screen and (max-width: 570px) {
    .contactMe-Wrapper{
        width: calc(100% - 128px);
        padding: 128px 64px;
        
    }
}
@media only screen and (max-width: 470px) {
    .contactMe-Wrapper{
        width: calc(100% - 64px);
        padding: 128px 32px;
        
    }
}
@media only screen and (max-width: 400px) {
    .SendMessage button{
        width: 125px;
        height: 35px;
    }
    .SendMessage button svg{
        width: 16px;
        height: 16px;
    }
}
@media only screen and (max-width: 345px) {
    .contactMe-Wrapper{
        width: 100%;
        padding: 128px 0px;
        
    }
}