@import url('https://fonts.googleapis.com/css2?family=Blinker&family=Poppins&display=swap');
img{
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
p{
  font-family: 'Blinker', sans-serif;
  font-size: 32px;
  color: #101010;
}

h1{
  font-family: 'Poppins', sans-serif;
  font-size: 48px;
  font-weight: bold;
  color: #101010;
}
h2{
  font-family: 'Poppins', sans-serif;
  font-size: 40px;
  font-weight: bold;
  color: #101010;
}
h3{
  font-family: 'Poppins', sans-serif;
  font-size: 36px;
  color: #101010;
}
h4{

  font-family: 'Poppins', sans-serif;
  font-size: 32px;
  font-weight: bold;
  color: #101010;

}
h5{
  font-family: 'Poppins', sans-serif;
  font-size: 24px;
  font-weight: bold;
  color: #101010;
}
h6{
  font-family: 'Poppins', sans-serif;
  font-size: 20px;
  font-weight: bold;
  color: #101010;
}

.color-primary{
  color: #FA3F26;

}
.color-secondary{
  color: #FD7619;
}
.color-grad{
  background: linear-gradient(90deg, rgba(250,63,38,1) 0%, rgba(253,118,25,1) 100%);
  /* background: -webkit-linear-gradient(#eee, #333); */
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.color-black{
  color: #101010;
}

a{
  text-decoration: none;
  color: #101010;
}
