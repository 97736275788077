@import url('https://fonts.googleapis.com/css2?family=Exo+2:wght@700&family=Paytone+One&family=Rubik+Mono+One&family=Titillium+Web&display=swap');

.hero{
    width: 100%;
    height: auto;
    min-height: 400px;
    display: flex;
    justify-content: center;
    padding-top: 64px;
}
.hero .AboutMe{
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding-left: 128px;
}
.hero .AboutMe .aboutme-info{
    position: absolute;
}
.hero .AboutMe .aboutme-info h3{
    margin-block: 0;
}
.hero .AboutMe .aboutme-info p{
    margin-block: 16px;
}
.hero .showcase{
    width: 50%;
    height: 400px;
    padding-right: 128px;
    /* display: none; */
}
.hero .showcase img{
    width: 100%;
    height: 400px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 2px 2px 3px 3px rgba(0, 0, 0, 0.2);
}

.mySkills{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.mySkills h4{
    padding: 8px 64px;
    box-shadow: -2px 5px 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 3px;
    text-shadow: 0px 3px rgba(0, 0, 0, 0.2);
    font-family: 'Titillium Web', sans-serif;
    letter-spacing: 1px;
    color: rgba(0, 0, 0, 0.82);
    
}

.mySkills .slider{
    width: 100%;
    height: 200px;
    /* display: grid;
    grid-template-columns: repeat(5,1fr);
    grid-template-rows: 200px; */
}
.mySkills .slider-item{
    width: 200px;
    height: 200px;
    /* box-shadow: -2px 5px 5px 2px rgba(0, 0, 0, 0.2); */
}
.mySkills .slider-item img{
    width: 100%;
    height: 100%;
    object-fit:scale-down;
}



.skills_container{
    width: calc(100% - 512px);
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 64px 256px;
    padding-top: 32px;
    gap: 1rem;
}
.flex_row{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
}

.skill_card{
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    padding: 0.15rem 2rem;
    box-shadow: -2px 5px 5px 2px rgba(0, 0, 0, 0.2);
    border-radius: 50px;
}

.skill_card img{
    width: 30px;
    object-fit: contain;
}

/* Grow Shadow */
.hvr-grow-shadow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
  }
  .hvr-grow-shadow:hover, .hvr-grow-shadow:focus, .hvr-grow-shadow:active {
    box-shadow: 0 10px 10px -10px rgba(0, 0, 0, 0.5);
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }




/* Reponsive */

@media only screen and (max-width: 1700px) {
    .hero .AboutMe .aboutme-info p{
       word-wrap: normal;
       font-size: 24px;
    }
}

@media only screen and (max-width: 1400px) {
    .hero .AboutMe{
       width: 60%;
    }
    .hero .showcase{
        width: 40%;
    }
}

@media only screen and (max-width: 1180px) {
    .hero .AboutMe .aboutme-info p{
        font-size: 18px;
     }
}

@media only screen and (max-width: 1000px) {
    .hero {
        flex-direction: column;
     }
     .hero .AboutMe{
        width: 100%;
        height: 400px;
        justify-content: center;
        align-items: center;
        padding-left: 0;
     }
     .hero .showcase{
        width: calc(100% - 256px);
        padding-inline: 128px;
    }
}

@media only screen and (max-width: 600px) {
     .hero .showcase{
        width: calc(100% - 128px);
        padding-inline: 64px;
    }
}
@media only screen and (max-width: 515px) {
    .flex_row{
        flex-direction: column;
    }
}
@media only screen and (max-width: 400px) {
     .hero .showcase{
        width: 100%;
        padding-inline: 0;
    }
}
