.navbar{
    width: 100%;
    height: auto;
    min-height: 80px;
    border-bottom: 1px solid #DDDDDD;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all 1s ease-in-out;

}
.navbar .logo{
    width: 64px;
    height: 64px;
    padding-left: 64px;
    order: 1;
} 
.nav {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-right: auto;
    padding-left: 2rem;
    order: 2;
    padding-left: 64px;
}

.navbar .logo img{
    width: 100%;
    height: 100%;
}
.nav-item{
    font-weight: unset;
    margin-block: 25px;
}

.nav-item.active{
    font-weight: bold;
    position: relative;
}
/* .nav-item.active::after{
    content: "";
    width: 80px;
    height: 5px;
    background: rgb(255, 81, 0);
    position: absolute;
    top: 40px;
    left: 10px;
    border-radius: 10px;
} */
.contact-info{
    display: flex;
    gap: 0.75rem;
    padding-right: 64px;
    font-family: 'Poppins', sans-serif;
    order: 3;
}

.nav-toggle{
    display: none;
    cursor: pointer;
}
.nav-toggle svg{
    width: 32px;
    height: 80px;
}


/* Reponsive */

@media only screen and (max-width: 1250px) {
    .nav .logo{
        width: 48px;
        height: 48px;
    } 
    .nav-item{
        font-size: 16px;
    }
    .contact-info span{
        font-size: 16px;
    }
}
@media only screen and (max-width: 950px) {
    .contact-info{
        display: none;
    }
}
@media only screen and (max-width: 680px) {
    .nav {
        flex-direction: column;
    }
    .nav-toggle {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .nav{
        height: 80px;
        overflow: hidden;
        transition: all 0.5s ease-in-out;
    }
    .nav.active{
        /* display: flex; */
        height: 400px;
    }
    .navbar .logo{
        padding-right: 64px;
        padding-left: 0;
        padding-top: 8px;
        order: 3;
        align-self: flex-start;
    }
}