.notfound_container{
    width: 100%;
    height: calc(100vh - 260px) ;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.notfound_image{
    width: 50%;
}

@media only screen and (max-width: 900px) {
    .notfound_container h2{
        font-size: 24px;
    }
    .notfound_image{
        width: 70%;
    }
    
}
