footer{
    width: 100%;
    height: 80px;
    border-top: 1px solid rgb(0, 0, 0,0.2);
    margin-top: 50px;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
}

.Contact_info{
    padding-left: 64px;
    display: flex;
    gap: 0.5rem;
    margin-right: auto;
    
}
.Contact_info span{
    font-family: 'Poppins',sans-serif;
    font-size: 16px;
}

.copyright{
    justify-self: center;
    margin-left: auto;
    margin-right: auto;
}
.copyright h6{
    font-size: 18px;
}

.social{
    padding-right: 64px;
    display: flex;
    gap: 1rem;
    margin-left: auto;
}

.social svg{
    width: 32px;
    height: 32px;
}


/* Reponsive */

@media only screen and (max-width: 1150px) {
    .Contact_info span{
        font-size: 12px;
    }
    .copyright h6{
        font-size: 14px;
    }
    .social svg{
        width: 24px;
        height: 24px;
    }
}

@media only screen and (max-width: 900px) {
    .Contact_info span{
        font-size: 12px;
    }
    .Contact_info .Contact_info--Splitter{
        display: none;
    }
    .Contact_info .Contact_info--phone{
        display: none;
    }
    .copyright h6{
        font-size: 14px;
    }
    .social svg{
        width: 24px;
        height: 24px;
    }
}
@media only screen and (max-width: 850px) {

    .copyright h6{
        font-size: 12px;
    }
}
@media only screen and (max-width: 700px) {
    footer{
        grid-template-columns: repeat(2,1fr);
    }
    .Contact_info{
        display: none;
    }
    .copyright h6{
        font-size: 12px;
    }
    .social svg{
        width: 16px;
        height: 16px;
    }
}
@media only screen and (max-width: 400px) {
    .copyright h6{
        font-size: 10px;
    }
}