.title{
    text-align: center;
    margin-top: 128px;
    text-shadow: 4px 4px 5px rgba(0, 0, 0, 0.15);
}

.Projects-Wrapper{
    padding: 64px 256px;
}

.Projects{
    width: 100%;
    height: auto;
    min-height: 90vh;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    grid-template-rows: repeat(3,320px);
    gap: 0.5rem;;
}

.Project{
    width: 100%;
    height: 100%;
    transition: all 0.3s;
    overflow: hidden;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
}
.Project:hover{
    transform: scale(1.1);
    z-index: 2;
}

.Project img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.Project_Live{
    width: 100%;
    height: 250px;
    overflow: hidden;
    background-color: white;
    position: relative;
}

.product_detail{
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    font-family: 'Courier New', Courier, monospace;
    text-shadow: 2px 2px rgba(0, 0, 0, 0.15);
    background-color: rgb(59, 59, 59);
    color: white;
}

.preview {
    width: 100%;
    min-width: 1920px;
    height: 100%;
    min-height: 1480px;
    overflow: hidden;
    pointer-events: none;
    zoom: 0.50;
    z-index: 2;
    -moz-transform: scale(0.182);
    -moz-transform-origin: 0 0;
    -o-transform: scale(0.182);
    -o-transform-origin: 0 0;
    -webkit-transform: scale(0.182);
    -webkit-transform-origin: 0 0;
}
.preview:hover {
    z-index: 3;
}

#spinner{
    z-index:99999;
    position:relative;
    width: 100%;
    height: 100%;
}
#spinner img{
    width: 64px;
    height: 64px;
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    background-color: #fff;
}

/* Responsive */


@media only screen and (max-width: 1150px) {
    .Projects-Wrapper{
        padding: 64px 128px;
    }
}
@media only screen and (max-width: 950px) {
    .title{
        font-size: 24px;
    }
}
@media only screen and (max-width: 900px) {
    .Projects{
        grid-template-columns: repeat(2,1fr);
        grid-template-rows: repeat(2,320px);
    }
}
@media only screen and (max-width: 650px) {
    .title{
        font-size: 18px;
    }
    .Projects{
        grid-template-columns: repeat(1,1fr);
        grid-template-rows: repeat(1,320px);
    }
}
@media only screen and (max-width: 490px) {
    .Projects-Wrapper{
        padding: 64px 64px;
    }
}
